<template>
  <!--portal企业控制台首页-->
  <div class="page-container">
      <div class="header-box">
        <ul class="head">
          <li id="logo">
            <img class="img" src="~@/assets/img/Slice 19.png" @click="ToHomepage" alt="" />
          </li>
        </ul>
        <ul class="head" style="margin-right: 15px">
          <li v-if="userInfo.teamId" class="nav"  @click="handleIcon">
            <div class="FristPage">
              印刷管家
            </div>
            <img class="icon-order" src="~@/assets/img/icon-order.png" alt="">
          </li>
        </ul>

        <div @click="handleIcon1" class="right-box">
          <!--          <div class="name">-->
          <!--            <span style="color:#F3A000">{{ userInfo.userName }}</span>-->
          <!--          </div>-->
          <!--          <el-tooltip class="item" effect="dark" :content="userInfo.userName" placement="bottom">-->
          <!--            <div class="name">-->
          <!--&lt;!&ndash;              您好！用户&ndash;&gt;-->
          <!--&lt;!&ndash;              <span style="color:#F3A000">{{ userInfo.userName }}</span>&ndash;&gt;-->
          <!--            </div>-->
          <!-- </el-tooltip> -->
          <el-dropdown>
            <div class="block">
              <el-avatar
                  :size="30"
                  :src="defaultUrl ? defaultUrl : require(`@/assets/img/headerImg.png`)"
              ></el-avatar>
              <!--              <el-avatar :size="54" :src="require(`@/assets/img/headerImg.png`)"></el-avatar>-->
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 150px;padding: 10px 0px 0px;font-size: 12px">
              <ol>
                <li class="li-text li1" @click="exit">
                  <div style="display: flex;padding: 10px 24px; margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 37.png" alt="img" />
                    </div>
                    退出登录
                  </div>
                </li>
              </ol>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="menu-app" :class="iconImg === true ? 'activeImg' : ''">
          <ul class="app-ul">
            <li>
              <router-link :to="`/PrintSteward/PrintHome`">
                <img src="~@/assets/img/icon-home1.png" alt="">
                主页
              </router-link>
            </li>
            <li >
              <router-link :to="`/PrintSteward/boss`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示老板
              </router-link>
            </li>
            <li>
              <router-link :to="`/PrintSteward/workshop`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示车间
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--<HomeWelcome />-->
      <div class="page-inner home-box">
        <!--上-->
        <div class="page-top">
          <!--左：员工总数、设备总数-->
          <div style="width: 550px" class="flex-full mr-30">
            <el-row type="flex" :gutter="30" class="mb-40">
              <!--员工总数-->
              <el-col :span="12">
                <div class="card-box1">
                  <div class="card-head">
                    <div class="card-title">员工总数</div>
                  </div>
                  <div
                      class="card-body"
                      :style="{ backgroundImage: 'url(' + require('@/assets/img/Slice10.png') + ')' }"
                  >
                <span class="number">
                  {{ data.userCount }}
                </span>
                  </div>
                </div>
              </el-col>
              <!--设备总数-->
              <el-col :span="12">
                <div class="card-box1 is-bg">
                  <div class="card-head">
                    <div class="card-title">设备总数</div>
                  </div>
                  <div
                      class="card-body"
                      :style="{ backgroundImage: 'url(' + require('@/assets/img/Slice11.png') + ')' }"
                  >
                    <div class="number">
                      {{ data.deviceCount }}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!--数采设备总数-->
            <div class="card-box1 is-bg">
              <div class="card-head">
                <div class="card-title">数采设备总数</div>
              </div>
              <div
                  class="card-body"
                  :style="{ backgroundImage: 'url(' + require('@/assets/img/Slice12.png') + ')' }"
              >
                <div class="left">
                  <span class="number">{{ data.allcount }}</span>
                </div>

                <div class="right">
                  <div class="right-top">
                    <span class="tag tag-warning mr-10">在线</span>
                    <span class="number">{{ data.okcount }}</span>
                  </div>

                  <div class="right-bottom">
                    <span class="tag mr-10">离线</span>
                    <span class="number">{{ data.nocount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--右：员工活跃度-->
          <div style="width: 1000px" class="flex-full">
            <div class="card-box1">
              <div class="card-head">
                <div class="card-title">员工日活跃度</div>
              </div>

              <div class="card-body card-body2" style="display: block;padding:25px 40px 40px;height: 378px">
                <div style="font-size: 16px;color: #000;line-height: 1;">活跃趋势</div>
                <div
                    v-if="chartData.source.length === 0"
                    class="text-center note pv-20"
                >
                  暂无数据
                </div>

                <BarChart
                    v-if="chartData.source.length > 0"
                    :id="'chartData'"
                    :data="chartData"
                    :height="300"
                    :barColor="barColor"
                    :legendShow="false"
                />
              </div>
            </div>
          </div>
        </div>
        <!--下：系统设置-->
        <div v-if="teamOperate || deviceOperate" class="page-bottom">
          <div class="section-title">系统设置</div>
          <div class="d-flex">
            <!--左：分配角色-->
            <div v-if="teamOperate" style="width: 40%" class="mr-30">
              <div class="card-box2">
                <div class="card-head">
                  <div class="card-title">分配角色</div>
                  <!--提示框-->
                  <el-popover
                      placement="bottom-end"
                      width="350"
                      trigger="hover"
                      content="企业负责人可手动添加公司成员时选择角色，也可在已添加成功的成员中重新分配其角色。人事、设备、维修相关人员设置完成后，方可使用本系统完整的功能。"
                  >
                    <img slot="reference" alt="btn" src="@/assets/img/Slice13.png" />
                  </el-popover>
                </div>

                <div class="card-body">
                  <div class="img-box" @click="toPage('teamUser', '0ea0531a-94c9-4b57-90cb-7315b8c2b961')">
                    <img alt="img" src="@/assets/img/Slice3.png" />
                    <div class="text">
                      人事主管
                      <br />
                      设置
                    </div>
                  </div>

                  <div class="img-box disable">
                    <img alt="img" src="@/assets/img/Slice14.png" class="separated" />
                  </div>
                  <div class="img-box" @click="toPage('teamUser', '58785e2a-0c3c-499a-a8e6-918a3096b6ba')">
                    <img alt="img" src="@/assets/img/Slice1.png" />
                    <div class="text">
                      保养人员
                      <br />
                      设置
                    </div>
                  </div>

                  <div class="img-box disable">
                    <img alt="img" src="@/assets/img/Slice14.png" class="separated" />
                  </div>

                  <div class="img-box" @click="toPage('teamUser', '29489e7d-9a82-46a7-a3dd-151a6adc6713')">
                    <img alt="img" src="@/assets/img/Slice2.png" />
                    <div class="text">
                      点检人员
                      <br />
                      设置
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--右：添加设备-->
            <div v-if="deviceOperate" style="width: 60%">
              <div class="card-box2">
                <div class="card-head">
                  <div class="card-title">添加设备</div>
                  <!--提示框-->
                  <el-popover
                      placement="bottom-end"
                      width="350"
                      trigger="hover"
                      content="企业负责人可手动添加设备，选择相应的保养和点检人员，选择系统模板并形成保养策略，添加设备成功后，方可使用本系统完整的功能。"
                  >
                    <img slot="reference" alt="btn" src="@/assets/img/Slice13.png" />
                  </el-popover>
                </div>

                <div class="card-body">
                  <div class="img-box" @click="toPage('templateList')">
                    <img alt="img" src="@/assets/img/Slice6.png" />
                    <div class="text">
                      维护
                      <br />
                      保养策略
                    </div>
                  </div>

                  <div class="img-box disable">
                    <img alt="img" src="@/assets/img/Slice14.png" class="separated" />
                  </div>

                  <div class="img-box" @click="toPage('deviceAdd', '', 'maintainPerson')">
                    <img alt="img" src="@/assets/img/Slice4.png" />
                    <div class="text">
                      选择
                      <br />
                      保养人员
                    </div>
                  </div>

                  <div class="img-box disable">
                    <img alt="img" src="@/assets/img/Slice14.png" class="separated" />
                  </div>

                  <div class="img-box" @click="toPage('deviceAdd', '', 'checkPerson')">
                    <img alt="img" src="@/assets/img/Slice5.png" />
                    <div class="text">
                      选择
                      <br />
                      点检人员
                    </div>
                  </div>

                  <div class="img-box disable">
                    <img alt="img" src="@/assets/img/Slice14.png" class="separated" />
                  </div>

                  <div class="img-box disable">
                    <img alt="img" src="@/assets/img/Slice7.png" />
                    <div class="text">
                      添加设备
                      <br />
                      成功
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';
import BarChart from '@/components/Charts/BarChart';
import {store} from "@/store";
import cookies from "@/utils/cookies"; // 图表组件
// import HomeWelcome from '@/components/HomeWelcome' // 首页欢迎
export default {
  // name: 'PrintSteward',
  // components: { HomeWelcome },
  components: { BarChart },
  data() {
    return {
      VUE_APP_HOME: process.env.VUE_APP_LOGIN,
      iconImg: false,
      teamOperate: false, // 操作人员权限
      deviceOperate: false, // 操作设备
      // 页面数据
      data: {},
      // 图表
      chartData: {
        // 数据集
        source: [],
        // y轴
        // yAxis: {
        //   type: 'value',
        //   // 坐标轴线
        //   axisLine: {
        //     show: true,
        //     lineStyle: {
        //       color: '#CACACA',
        //       width: 2
        //     }
        //   }
        // },
        // 系列
        series: [
          {
            type: 'bar',
            barWidth: 30,
          },
        ],
      },
      // 柱形颜色
      barColor: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FA460D' },
          { offset: 1, color: '#F7B515' },
        ]),
      ],
      userInfo: {
        userName: '',
        url: '',
        phone: '',
        email: '',
        teamName: '',
      },
      defaultUrl: '',
    };
  },
  created() {
    this.getData();
    this.getUserInfo();
  },
  watch: {
    // 监听VUEX中buttons
    buttons: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log(2, val)
        if (val && val.length > 0) {
          // console.log(3, val)
          this.managePermission();
        }
      },
    },
  },
  computed: {
    // 获取VUEX中buttons
    buttons() {
      return this.$store.getters.userInfo.buttons;
    },Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },

  },
  methods: {
    // 获取数据
    getData() {
      API.printHome().then((res) => {
        // console.log(res.message.data)
        this.data = res.message.data;
        // 处理图表数据
        const { list } = res.message.data;
        const source = [];
        if (list.length > 0) {
          for (const item of list) {
            source.push([item.time, item.count]);
          }
          this.chartData.source = [
            ['product', '日活跃度'],
            ...source,
          ];
        } else {
          this.chartData.source = [];
        }
      });
    },
    // 是否有操作人员、设备权限
    managePermission() {
      // 人员权限
      if (manageOauth('team_operate', this.buttons)) {
        this.teamOperate = true;
      }
      // 设备权限
      if (manageOauth('device_operate', this.buttons)) {
        this.deviceOperate = true;
      }
    },
    // 跳转页面
    toPage(path, roleId, ref) {
      this.$router.push({
        path,
        query: {
          roleId, // 角色id
          ref, // 自动弹出下拉框的ref
        },
      });
    },
    handleIcon() {
      if (this.iconImg === true) {
        this.iconImg = false
      } else {
        this.iconImg = true
      }
    },
    handleIcon1() {
      if (this.iconImg === true) {
        this.iconImg = false
      }
    },
    // 返回portal首页
    ToHomepage() {
      // window.open(`${this.VUE_APP_HOME}`);
    },
    // 退出登录
    exit() {
      this.$confirm('确定要退出此用户?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            store.dispatch('logout').then(() => {
              cookies.remove('token');
              window.open(`${this.VUE_APP_HOME}login/:SUP/`, '_self');
            });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消',
            // });
          });
    },
    // 获取个人信息
    getUserInfo() {
      store.dispatch('getInfo').then((res) => {
        console.log(res)
        if (res.message.success) {
          this.userInfo = res.message.data;
          // console.log(this.userInfo)
          this.defaultUrl = res.message.data.url;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {

  .page-inner {
    /*height: 100%;
    overflow: auto;
    margin-right: -40px;*/

    .page-top,
    .page-bottom {
      /*min-width: 1620px;
      padding-right: 40px;*/
      margin-bottom: 30px;
    }

    .page-top {
      display: flex;
    }
  }
}
.section-title {
  padding-left: 0;
  border-left: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}
/*卡片*/
.card-box1 {
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/

  .card-head {
    height: 38px;

    .card-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
    }
  }
  .card-body {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 50px 40px;
    background-color: #fff;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    transition: background 0.3s;
    border-radius: 2px;
    height: 150px;
    box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
    /*左*/
    .left {
      width: 40%;
    }
    /*右*/
    .right {
      width: 60%;
      display: flex;
      /*margin-left: auto;*/
      .number {
        font-size: 26px;
        font-family: 'DIN-Medium';
        line-height: 1;
      }
      /*右上*/
      .right-top {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }
      /*右下*/
      .right-bottom {
        display: flex;
        align-items: center;
      }
    }
    .number {
      font-size: 50px;
      font-family: 'DIN-Bold';
      line-height: 1;
    }
  }
}
.card-box2 {
  background: #fff;
  border-radius: 2px;
  padding: 30px 40px 40px;
  height: 100%;
  box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);

  .card-head {
    display: flex;
    justify-content: space-between;
    height: 31px;

    .card-title {
      font-size: 16px;
      color: #000;
      line-height: 1;
    }
  }
  .card-body {
    display: flex;
    justify-content: center;
  }
}
/*标签*/
.tag {
  font-size: 14px;
  line-height: 1;
  border-radius: 2px;
  padding: 4px 10px;
  background: #858585;
  color: #fff;
  white-space: nowrap;

  &.tag-warning {
    background: #f7633c;
  }
}
/*图片组*/
.img-box {
  text-align: center;
  cursor: pointer;
  // 禁用
  &.disable {
    cursor: initial;
  }

  img {
    max-width: 100%;
    /*分隔*/
    &.separated {
      padding: 40px 0;
    }
  }
  .text {
    font-size: 14px;
  }
}
.header-box {
  display: none;
}
@media only screen and (max-width: 1366px) {
  .card-box1 {
    .card-body {
      .number {
        font-size: 40px;
      }
      .right {
        .right-top {
          margin-right: 10px;
        }
      }
    }
  }
}
@media screen and (max-width:800px){
  .header-box {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    background: #3e2b25;
    box-shadow: 0px 2px 6px 3px #bcbcbc;
    padding: 0 50px;
    position: fixed;
    width: 100%;
    z-index: 99999;
    .menu-app {
      display: none;
      position: absolute;
      bottom: -750px;
      left: 0;
      background-color: #fff;
      z-index: 9999;
      width: 100%;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      box-shadow: 0rem 0px 18px 0px #aaa6a6;
      ul{
        list-style: none;
        padding: 0 100px;
        li {
          display: block;
          border-bottom: 1px solid #E1E1E1;
          font-size: 78px;
          color: #000;
          padding: 70px 0;
          text-align: center;
        }
        img {
          width: 70px;
          margin-right: 20px;
        }
        a{
          text-decoration: none;
          color: #000;
          display: inline-block;
          width: 600px;
          text-align: left;
        }
      }
    }
    .activeImg {
      display: block;
    }
    .right-box {
      float: right;
      //margin-right: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    #logo {
      //height: 62px;
      //width: 202px;
      text-align: center;
      .img {
        margin-top: 30px;
        //height: 41px;
        width: 300px;
      }
    }
    .head li {
      font-size: 88px;
      color: #fff !important;
      //width: 80px;
      //height: 62px;
      //line-height: 62px;
      position: relative;
      align-items: center;
      float: left;
      //font-weight: bold;
      // margin-right: 80px;
      cursor: pointer;
      display: flex;
      .exit {
        width: 120px;
        height: 57px;
        line-height: 57px;
      }
    }
    .icon-order {
      width: 100px;
      height: 70px;
      margin-left: 30px;
    }
    .exit:hover {
      color: #f3a000;
    }
    .head > li a:hover {
      color: #f3a000;
    }
    .head {
      .active {
        color: #fff;
        text-decoration: none;
        transition: all ease 0.3s;
      }
    }
    .UserName {
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
      position: relative;
    }
    .UserName:hover .menu {
      display: block;
      transition: all ease 0.3s;
    }
    .information {
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
    }
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      border: 1px solid #9999;
      background: #ffffff;
      z-index: 9;
      .pullDown {
        width: 62px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        display: block;
      }
      li {
        width: 62px;
        height: 60px;
        color: #231815;
      }
    }
    .pullDown:hover {
      color: #f3a000;
      transition: all ease 0.3s;
    }
    .menu > li:hover a {
      color: #f3a000;
      transition: all ease 0.3s;
    }
  }
  .activeImg {
    display: block;
  }
  .mr-30 {
    width: 100% !important;
  }
  .page-container {
    padding: 0!important;
    .home-box {
      padding: 40px;
    }
    .page-inner {
      /*height: 100%;
      overflow: auto;
      margin-right: -40px;*/

      .page-top,
      .page-bottom {
        /*min-width: 1620px;
        padding-right: 40px;*/
        margin-bottom: 30px;
        margin-top: 250px;
      }

      .page-top {
        display: block;
      }
    }
  }
  .section-title {
    padding-left: 0;
    border-left: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
  /*卡片*/
  .card-box1 {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/

    .card-head {
      height: 150px;

      .card-title {
        font-size: 78px;
        font-weight: bold;
        line-height: 1;
        margin-top: 20px;
      }
    }
    .card-body {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      padding: 300px 180px;
      background-color: #fff;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      transition: background 0.3s;
      border-radius: 2px;
      height: 150px;
      box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
      /*左*/
      .left {
        width: 40%;
      }
      /*右*/
      .right {
        width: 60%;
        display: flex;
        /*margin-left: auto;*/
        .number {
          font-size: 120px;
          font-family: 'DIN-Medium';
          line-height: 1;
        }
        /*右上*/
        .right-top {
          display: flex;
          align-items: center;
          margin-right: 100px;
        }
        /*右下*/
        .right-bottom {
          display: flex;
          align-items: center;
        }
      }
      .number {
        font-size: 180px;
        font-family: 'DIN-Bold';
        line-height: 1;
      }
    }
    .card-body2 {
      padding: 150px 100px !important;
    }
  }
  .card-box2 {
    background: #fff;
    border-radius: 2px;
    padding: 30px 40px 40px;
    height: 100%;
    box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);

    .card-head {
      display: flex;
      justify-content: space-between;
      height: 31px;

      .card-title {
        font-size: 16px;
        color: #000;
        line-height: 1;
      }
    }
    .card-body {
      display: flex;
      justify-content: center;
    }
    .card-body2 {
      padding: 0 !important;
    }
  }
  /*标签*/
  .tag {
    font-size: 68px;
    line-height: 1;
    border-radius: 15px;
    padding: 30px 20px;
    background: #858585;
    color: #fff;
    white-space: nowrap;

    &.tag-warning {
      background: #f7633c;
    }

  }
  /*图片组*/
  .img-box {
    text-align: center;
    cursor: pointer;
    // 禁用
    &.disable {
      cursor: initial;
    }

    img {
      max-width: 100%;
      /*分隔*/
      &.separated {
        padding: 40px 0;
      }
    }
    .text {
      font-size: 14px;
    }
  }
  .flex-full {
    width: 100% !important;
  }
  .mb-40 {
    margin: 0!important;
  }
  .el-col-12 {
    //width: 48% !important;
    padding: 15px !important;
  }
  .mr-10 {
    margin-right: 30px!important;
  }
}
</style>
